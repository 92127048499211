import React from 'react';

export class Paragraph extends React.Component{
    render(){
        const {children, color, style} = this.props;
        const defaultStyle = {
            margin: '4px'
        }
        Object.assign(defaultStyle, {color})
        Object.assign(defaultStyle, style)
    
        return <p style={defaultStyle}>{children}</p>
    }
}
